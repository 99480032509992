import styled from 'styled-components';

export const Heading = styled.h2`
  margin: 3rem auto 10px;
`;

export const HeadingSmall = styled.h4`
  font-weight: 100;
  line-height: 1.4;
  color: #6a7990;
`;
