import * as React from 'react';
import Link from 'next/link';
import { Container, Row, Col } from 'react-grid-system';
import { LinkButton } from '@app/components/ui/Button';
import { Heading, HeadingSmall } from './ErrorView.styles';

export const ErrorView: React.FC = () => {
  return (
    <Container style={{ textAlign: 'center' }}>
      <Row nogutter align="center">
        <Col>
          <Heading>Oh no! Page not found.</Heading>
          <HeadingSmall>
            While you&apos;re here, create your own custom Moon Map!
          </HeadingSmall>
        </Col>
      </Row>
      <Row nogutter align="center">
        <Col>
          <Link href="/create" passHref>
            <LinkButton variant="primary">Create Your Moon Map</LinkButton>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};
